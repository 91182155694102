import type { MetaFunction } from "@remix-run/node";
import { json, redirect } from "@remix-run/node";
import { useLoaderData } from "@remix-run/react";
import { RetreatList } from "~/features/RetreatList";
import { getBookableRetreats } from "~/repo/retreat";
import { retreatsPublicBasicSchema } from "~/schemas/retreat";

export const loader = async () => {
  const retreats = await getBookableRetreats();

  if (retreats.length === 1) {
    return redirect(`/${retreats[0].slug}`);
  }

  return json({
    retreats: retreatsPublicBasicSchema.parse(retreats),
  });
};

export const meta: MetaFunction = () => {
  return [{ title: "Online Retreats - Simply Always Awake" }];
};

export default function Index() {
  const loaderData = useLoaderData<typeof loader>();
  const retreats = retreatsPublicBasicSchema.parse(loaderData.retreats);

  return (
    <>
      <h1>Simply Always Awake online retreats</h1>

      <RetreatList retreats={retreats} />
    </>
  );
}
