import { Divider } from "@mantine/core";
import { Link } from "@remix-run/react";
import { DateRange } from "~/components/DateRange";
import { RetreatPublicBasic } from "~/schemas/retreat";

export const RetreatList = ({
  retreats,
}: {
  retreats: RetreatPublicBasic[];
}) => {
  return (
    <>
      {retreats.map((retreat) => (
        <Retreat retreat={retreat} key={retreat.slug} />
      ))}
    </>
  );
};

const Retreat = ({ retreat }: { retreat: RetreatPublicBasic }) => {
  return (
    <div className="">
      <Divider />
      <h3>{retreat.title}</h3>
      <p>
        <DateRange start={retreat.start_date} end={retreat.end_date} />
      </p>
      <p>
        <Link to={`/${retreat.slug}`}>More info & booking</Link>
      </p>
    </div>
  );
};
